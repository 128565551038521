import { createContext, useContext } from 'react';

import type { AsyncHookResult } from '../helpers';

import type { Variant } from './experiments';

export const MetaConfigContext = createContext({
  useMetaConfig(): AsyncHookResult<MetaConfig> {
    return { loading: false, error: new Error('Missing frame-config module') };
  },
});

export function useMetaConfig(): AsyncHookResult<MetaConfig> {
  return useContext(MetaConfigContext).useMetaConfig();
}

export type MetaConfigJson = {
  navigation: Record<string, Config>;
  experiments: Array<{
    name: `ABACUS-${number}`;
    supportedVariants: ['A', ...Variant[]];
  }>;
};

export type MetaConfig = MetaConfigJson & {
  url: string;
  version: number;
};

type SerializablePayloadArray = SerializablePayload[];

type SerializablePayloadObject = {
  [key: string]: SerializablePayload;
} & {
  $meta?: never;
} & {
  $component?: never;
};

type SerializablePayload =
  | null
  | string
  | boolean
  | number
  | { $meta: Config }
  | { $component: string }
  | SerializablePayloadArray
  | SerializablePayloadObject;

type SerializablePayloadWithComponentsArray =
  SerializablePayloadWithComponents[];

type SerializablePayloadWithComponentsObject = {
  [key: string]: SerializablePayloadWithComponents;
};

type SerializablePayloadWithComponents =
  | SerializablePayload
  | JSX.Element
  | React.ComponentType
  | SerializablePayloadWithComponentsArray
  | SerializablePayloadWithComponentsObject;

type ConfigItem = string | [string, SerializablePayloadObject];

type Config = ConfigItem[];
