import { createContext, useContext } from 'react';

import type { AsyncHookResult } from '../helpers';

type ViewerDataContextType = {
  loginState: LoginState;
  useViewerData(): AsyncHookResult<ViewerData>;
};

export const ViewerDataContext = createContext<ViewerDataContextType>({
  loginState: 'LOGGED_OUT',
  useViewerData(): AsyncHookResult<ViewerData> {
    return { loading: false, error: new Error('Missing viewer-data module') };
  },
});

export function useLoginState(): LoginState {
  return useContext(ViewerDataContext).loginState;
}

export function useViewerData(): AsyncHookResult<ViewerData> {
  return useContext(ViewerDataContext).useViewerData();
}

export type ViewerDataLI = {
  loginState: 'LOGGED_IN' | 'SOFT_LOGGED_IN';
  hashedUserId: string;
  hasThirdPartyTrackingConsent: boolean;
  networkEfficiencyIndicator: NetworkEfficiencyIndicator;
  user: XingId;
  willingnessToChangeJobs: number;
  propMemberships: string;
};

export type ViewerDataLO = {
  loginState: 'LOGGED_OUT';
  experiments?: undefined;
  hashedUserId?: undefined;
  hasThirdPartyTrackingConsent?: undefined;
  networkEfficiencyIndicator?: undefined;
  user?: undefined;
  willingnessToChangeJobs?: undefined;
  propMemberships?: undefined;
};

export type ViewerData = ViewerDataLO | ViewerDataLI;

export type XingId = {
  academicTitle: string;
  birthday: string;
  displayName: string;
  firstName: string;
  gender: string;
  globalId: string;
  id: string;
  lastName: string;
  occupations: Array<{
    headline: string;
    subline: string;
  }>;
  pageName: string;
  profileImage: Array<{
    url: string;
  }>;
  userFlags: {
    displayFlag: string;
  };
};

export type Quality = 'low' | 'medium' | 'high' | 'complete';

export type NetworkEfficiencyIndicator = {
  score?: number;
  category?: Quality;
  userTypeSupported?: boolean;
};

export type LoginState = 'LOGGED_IN' | 'LOGGED_OUT' | 'SOFT_LOGGED_IN';
